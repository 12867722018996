import React from 'react';
import { useState } from 'react';
// material
import {
    Stack,
    TextField,
    FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, Typography, Grid, Box, Button, useTheme, Select
} from '@mui/material';
// app
import { useTranslation } from 'react-i18next';
import {IdentityService} from 'src/api/services';
import BaseButton from 'src/components/buttons/BaseButton';
import {styled} from "@mui/system";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {getSelectOptions, IdentityType, OrganisationIdentityType, PersonalIdentityType} from "../../../../constants";
import {number} from "yup";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default function EditAddressForm({ setModalStatus, formData, setSnackbarStatus, setMessage, successCallback, countries }) {
    const getValueFromFormData = (key) => {
        const filtered = formData.filter((item) => item.key === key);
        if(filtered.length > 0) {
            return filtered[0].value;
        }
        return "";
    }

    const { t } = useTranslation();

    const [name, setName] = useState(getValueFromFormData("name"));
    const [address1, setAddress1] = useState(getValueFromFormData("address_line_1"));
    const [address2, setAddress2] = useState(getValueFromFormData("address_line_2"));
    const [city, setCity] = useState(getValueFromFormData("city"));
    const [postalCode, setPostalCode] = useState(getValueFromFormData("postal_code"));
    const [phoneNumber, setPhoneNumber] = useState(getValueFromFormData("phone_number"));
    const [additionalInfo, setAdditionalInfo] = useState(getValueFromFormData("additional_info_notes"));
    const [filterCountry, setFilterCountry] = useState(countries.filter((item) => item.value === getValueFromFormData("country_id"))[0].value);

    const [uploadedFileInfo, setUploadedFileInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [docType, setDocType] = useState(0);
    const [uploadProgressMap, setUploadProgressMap] = useState({});

    const addIdentity =() => {


        if(address1 === "" || city === "" || filterCountry === 0) {
            setMessage(t('Please fill mandatory fields'));
            setSnackbarStatus(true);
            return;
        }

        const payload = {
            name: name,
            address_line_1: address1,
            address_line_2: address2,
            city: city,
            country_id: filterCountry,
            postal_code: postalCode,
            phone_number: phoneNumber,
            additional_info_notes: additionalInfo
        };

        IdentityService.updateAddress(payload, getValueFromFormData("id"))
        .then((response) => {
            if (response.data.meta.code === 200) {
                setMessage(t('Address edited successfully'));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
                successCallback();
            } else {
                throw (t("could-not-be-edited"));
            }
        })
        .catch((err) => {
            setMessage(t('could-not-be-edited'));
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
        });
    }

    const theme = useTheme();

    const handleFileChange = () => (event) => {
        const selectedFile = event.target.files[0];
        const fileWithLabel = { file: selectedFile};
        setSelectedFile([fileWithLabel]);
    };

    const [isUploading, setIsUploading] = useState(false);
    const updateUploadProgress = (label, percentage) => {
        setUploadProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [label]: percentage,
        }));
    };

    const uploadFiles = async () => {
        setIsUploading(true);
        setMessage("Uploading Files");
        setSnackbarStatus(true);

        try {
            const promises = selectedFile.map((file) => {
                const form = new FormData();
                form.append('files', file.file);
                const onUploadProgress =   {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let precentage = Math.floor((loaded * 100) / total);
                        updateUploadProgress(file.label, precentage);
                    }
                }

                return IdentityService.uploadFile(form, onUploadProgress)
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.meta.code === 200) {
                                setMessage("File uploaded");
                                setSnackbarStatus(true);

                                const uploadedFiles = response.data.data;
                                const guidsByLabel = [];

                                uploadedFiles.forEach((uploaded) => {
                                    guidsByLabel.push({
                                        guid: uploaded.guid,
                                        filename: uploaded.filename,
                                    });
                                });

                                return guidsByLabel;
                            }
                        } else {
                            throw new Error("Error uploading file");
                        }
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                        setSnackbarStatus(true);
                    });
            });

            const results = await Promise.all(promises);
            setUploadedFileInfo(results);
            setMessage("File Uploaded");
            setSnackbarStatus(true);
        } catch (error) {
            console.error(error);
            setMessage(error.message.toString());
            setSnackbarStatus(true);
        } finally {
            setIsUploading(false);
        }
    };

    const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "primary",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "primary",
        },
    }));

    return (
        <Stack sx={{display: "flex"}} spacing={3}>
                <TextField
                    value={name}
                    label={'Name (Mandatory)'}
                    name="name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        setName(event.target.value);
                    }}
                />

                <TextField
                    value={address1}
                    label={'Address Line 1 (Mandatory)'}
                    name="companyName"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        setAddress1(event.target.value);
                    }}
                />

                <TextField
                    value={address2}
                    label={'Address Line 2 (Optional)'}
                    name="companyName"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        setAddress2(event.target.value);
                    }}
                />

                <TextField
                    value={city}
                    label={'City (Mandatory)'}
                    name="registrationNumber"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        setCity(event.target.value)
                    }}
                />

                <Select
                    label={'country'}
                    labelId="filter-country-label"
                    name="country"
                    value={filterCountry}
                    textAlign="left"
                    alignItems="left"
                    justifyContent="left"
                    sx={{textAlign: "left", alignItems: "left", justifyContent: "left"}}
                    onChange={event => {
                        setFilterCountry(event.target.value)
                    }}
                >
                    {getSelectOptions(countries, ["0", "Any"], "Country (Mandatory)")}
                </Select>


                <TextField
                    value={postalCode}
                    label={'Postal Code (Optional)'}
                    name="taxId"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        setPostalCode(event.target.value);
                    }}
                />
                <TextField
                    value={phoneNumber}
                    label="Phone Number (Optional)"
                    name="phone_number"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        const input = event.target.value;
                        if (/^[\d+]*$/.test(input)) {
                            setPhoneNumber(input);
                        }
                    }}
                />
                

                <TextField
                    value={additionalInfo}
                    label={'Additional Info/Notes (Optional)'}
                    name="description"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={event => {
                        setAdditionalInfo(event.target.value);
                    }}
                />
                <br/>

                <Stack sx={{display: "block", alignItems: "right"}} direction="row" spacing={2}>
                    <BaseButton
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </BaseButton>
                    <BaseButton
                        type="submit"
                        color="--color-primary"
                        variant="contained"
                        loading={loading}
                        onClick={() => addIdentity()}
                    >
                        {t('Save')}
                    </BaseButton>
                </Stack>
        </Stack>
    );
}
