import React from "react";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Card,
    CardContent
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { CommonService } from "src/api/services";


export default function TemplateFilesForm({template_files, setMessage, setSnackbarStatus}) {

    const handleDownloadFile = (guid, file_name) => {
        CommonService.downloadMediaFile(guid)
            .then((response) => {

                const blob = new Blob([response.data], {
                    type: response.headers['content-type']
                });
                
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                
                link.setAttribute('download', file_name);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
    
                setMessage("Template file downloaded successfully.");
                setSnackbarStatus(true);
            })
            .catch((err) => {
                const errorMessage = err.response?.data?.error || err.message || "Request could not be sent";
                setMessage(errorMessage);
                setSnackbarStatus(true);
            });
    };

  return (
    <Box sx={{ border: "1px solid #ccc", borderRadius: 2, p: 3, mb: 2 }}>
      <Typography
        variant="h6"
        component="legend"
        sx={{ mb: 2, fontWeight: "bold" }}
      >
        Template Files
      </Typography>
      <Grid container spacing={2}>
        {template_files.length > 0 && template_files.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 1, fontWeight: "bold" }}
                >
                  {item.template_name}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleDownloadFile(item.template_file_guid, item.template_file_name)}
                  fullWidth
                >
                  Download Template
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
