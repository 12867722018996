import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Typography, Box, Button, Divider, CircularProgress
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {
    DefaultPaginationData,
    getSelectOptions
} from 'src/constants/index';
import {CommonService, IdentityService, NumberService, PortingService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@mui/styles";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {useNavigate} from "react-router-dom";
import BaseButton from "../../../../components/buttons/BaseButton";
import { useTheme } from '@mui/material/styles';
import BaseSelect from 'src/components/BaseSelect';
import BaseTextField from 'src/components/BaseTextField';
import { LoadingButton } from '@mui/lab';
import { Loading } from 'redoc';
import TemplateFilesForm from 'src/components/TemplateFilesForm';

const useStyles = makeStyles((theme) => ({
    selectedCircle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
    circle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "darkgray",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
}));

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "primary",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "primary",
    },
}));

export default function NumberPortoutForm({formData, successCallback, closeCallback, setModalStatus, setSnackbarStatus, setMessage}) {
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    const [paginationData, setPaginationData] = useState(DefaultPaginationData);

    const [page, setSelectedPage] = useState(1);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const [filterCountry, setFilterCountry] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState(0);
    const [textFieldDoc, setTextFieldDoc] = useState({});
    const [isDocsFetched, setIsDocsFetched] = useState(false);

    const [filterRoutingType, setFilterRoutingType] = useState('2');
    const [filterVoiceEndpoint, setFilterVoiceEndpoint] = useState(0);
    const [leadTime, setLeadTime] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const classes = useStyles();
    const theme = useTheme();

    const fetchFields =  async () => {
        setIsDocsFetched(false);
        const params = {
            number_type: formData.type,
            country: formData.country_code,
            porting_type: 2,
        };
        PortingService.listSettings(params)
            .then((response) => {
                setTextFieldDoc([]);
                if(response.data.meta.code == 200){
                    setPortingSettingsId(response.data.data.porting_settings_id);
                    setTextFieldDoc(response.data);
                    setLeadTime(response.data.data.lead_time);
                    setIsDocsFetched(true);
                }
                setIsDocsFetched(true);

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }

    const fetchCountries = () => {
        CommonService.getCountries({porting_enabled:true})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        NumberService.getDIDTypes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"] });
                })
                setNumberTypes(items);
                console.log(items)
            })
            .catch((err) => {
                console.log(err);
            })
    };


    useEffect(() => {
        console.log(formData);
        fetchFields();
        fetchCountries();
        fetchDIDTypes();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    const initialTextFields = {
        "end_user_name": "",
        "billing_telephone": "",
        "account_number": "",
        "address": "",
        "comments": "",
    };

    const [textFieldsData, setTextFieldsData] = useState(initialTextFields);

    const handleTextFieldChange = (key, value) => {
        setTextFieldsData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
        console.log(textFieldsData);
    };


    const renderTextFields = () => {
        return textFieldDoc?.data?.text_fields?.map((field) => (
            <Grid item xs={12} key={field.key}>
                <TextField
                    label={field.label}
                    value={textFieldsData[field.key]}
                    onChange={(e) => handleTextFieldChange(field.key, e.target.value)}
                    fullWidth
                />
            </Grid>
        ));
    };

    const [uploadProgressMap, setUploadProgressMap] = useState({});


    const [uploadedFileInfo, setUploadedFileInfo] = useState([]);


    const [selectedFile, setSelectedFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = (label) => (event) => {
        const selectedFile = event.target.files[0];
        const fileWithLabel = { file: selectedFile, label: label };

        setSelectedFile((prevSelectedFiles) => [...prevSelectedFiles, fileWithLabel]);
    };

    const updateUploadProgress = (label, percentage) => {
        setUploadProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [label]: percentage,
        }));
    };

    const uploadFiles = async () => {
        setIsUploading(true);
        setMessage("Uploading Files");
        setSnackbarStatus(true);
        try {
            const promises = selectedFile.map((file) => {
                const form = new FormData();
                form.append('files', file.file);
                const onUploadProgress =   {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let precentage = Math.floor((loaded * 100) / total);
                        updateUploadProgress(file.label, precentage);
                    }
                }

                return NumberService.uploadFilesBuyNumber(form, onUploadProgress)
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.meta.code === 200) {
                                setMessage("File uploaded");
                                setSnackbarStatus(true);

                                const uploadedFiles = response.data.data;
                                const guidsByLabel = [];

                                uploadedFiles.forEach((uploaded) => {
                                    guidsByLabel.push({
                                        guid: uploaded.guid,
                                        document: file.label,
                                    });
                                });
                                return guidsByLabel;
                            }
                        } else {
                            throw new Error("Error uploading file");
                        }
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                        setSnackbarStatus(true);
                    });
            });

            const results = await Promise.all(promises);
            const mergedGuidsByLabel = results.reduce((acc, curr) => acc.concat(curr), []);

            setUploadedFileInfo(mergedGuidsByLabel);
            console.log(mergedGuidsByLabel);
            setMessage("All Files Uploaded");
            setSnackbarStatus(true);
        } catch (error) {
            console.error(error);
            setMessage(error.message.toString());
            setSnackbarStatus(true);
        } finally {
            setIsUploading(false);
        }



    };


    const [textFields, setTextFields] = useState([""]);


    const [activePage, setActivePage] = useState(0);


    const removeFile = (fileName) => {
        setSelectedFile(prevFiles => prevFiles.filter(file => file.file.name !== fileName));
    };



    const handleAddTextField = () => {
        setTextFields([...textFields, ""]);
    };

    const handleChangeTextField = (index, value) => {
        const updatedFields = [...textFields];
        updatedFields[index] = value;
        setTextFields(updatedFields);
        console.log(updatedFields);
    };

    const changeFilterType = async (value) => {
        setFilterNumberTypes(value);
        await fetchFields();
    }


    //----------------------------------------------



    //--------------------------------------------------------
    const [identities, setIdentities] = useState([]);
    const [addresses, setAddresses] = useState([]);

    const [filterIdentity, setFilterIdentity] = useState("");
    const [filterAddress, setFilterAddress] = useState("");

    const [complianceRequirements, setComplianceRequirements] = useState(undefined);
    const [textFieldValues, setTextFieldValues] = useState({});
    const [fileData, setFileData] = useState([]);

    const [portingSettingsIdState, setPortingSettingsId] = useState(undefined);

    useEffect(() => {
        
    }, [fileData]);

    useEffect(() => {
    }, [textFieldValues]);

    useEffect(() => {
        fetchComplianceRequirementsProduct();
    }, [filterIdentity]);
  


    const [textFieldsDataCompliance, setTextFieldsDataCompliance] = useState({});


    const handleTextFieldChangeCompliance = (event, id) => {
        setTextFieldsDataCompliance((prevValues) => ({
            ...prevValues,
            [id]: event,
        }));
    };


    const generatePayloadFromState = () => {

        const numbers = [formData.did];

        const payload = {
            country: formData.country_code,
            did_id: formData.did_id,
            number_type: formData.type,
            numbers: numbers,
            porting_type: 2,
            porting_settings_id: portingSettingsIdState,

            identity_id: filterIdentity,
            address_id: filterAddress,

            compliances: [],
        };

        fileData.forEach((item) => {
            payload.compliances.push({
                'id': item.id,
                'type': 1,
                'data_file': {
                    'name': item.data_file.name,
                    'extension': item.data_file.extension,
                    'mime_type': item.data_file.mime_type,
                    'data': item.data_file.data
                }
            });
            }
        );


        Object.keys(textFieldsDataCompliance).forEach((key) => {
            payload.compliances.push({
                'id': parseInt(key),
                'type': 2,
                'data_text': {
                    'value': textFieldsDataCompliance[key]
                }
            });
            }
        );

        return payload;
    };

    const sendPortOutRequest = (goTranslation) => {


        if(filterIdentity == undefined || filterIdentity == null || filterAddress == undefined || filterAddress == null || filterIdentity == 0 || filterAddress == 0){
            setMessage("Please select an identity and address");
            setSnackbarStatus(true);
            return;
        }

        if(portingSettingsIdState == undefined || portingSettingsIdState == null){
            setMessage("No porting settings found for this request");
            setSnackbarStatus(true);
            return;
        }

        
        const payload = generatePayloadFromState();

        let missingFields = [];

        if(complianceRequirements != undefined && complianceRequirements.fields.length > 0){
            complianceRequirements.fields.forEach((item) => {
                if(item.field_status == 2 && item.field_type == 1){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 1){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }else if(item.field_status == 2 && item.field_type == 2){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 2){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }
            });
        }

        
        if(missingFields.length > 0){
            setMessage("Please fill in the following fields: " + missingFields.join(", "));
            setSnackbarStatus(true);
            return;
        }

        const textFieldsArray = [];

        for (const key in textFieldsDataCompliance) {
            const value = textFieldsDataCompliance[key];
            textFieldsArray.push({
                key: key,
                value: value,
            });
        }


        setLoading(true);

        PortingService.add(payload)
            .then((response) => {
                if (response.data.status === true) {
                    setMessage("Porting request has been succesfully processed");
                    setSnackbarStatus(true);
                    successCallback();
                    closeCallback();
                    setModalStatus(false);
                } else {
                    throw {message:"Request could not be sent", response:response};
                }
                setLoading(false);

            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
            })
    }
  
    const handleFileChangeCompliance = (event, id) => {
        const file = event.target.files[0];

        const selectedFile = event.target.files[0];
        const reader = new FileReader();
  
          reader.readAsDataURL(selectedFile);
  
          reader.onload = () => {
              const base64 = reader.result;
              const file = {
                  id: id,
                  data_file: {
                      data: base64,
                      name: selectedFile.name,
                      extension: selectedFile.name.split('.').pop(),
                      mime_type: selectedFile.type
                  }
              };
              setFileData((prevFiles) => [...prevFiles.filter(file => file.id !== id), file]);
          }

    };

    const fetchIdentities = () => {
        const params = {
            pagination:2
        };
        IdentityService.listIdentities(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data?.data.forEach((item) => {
                        items.push({ code: item["id"], name: item["identity_name"], label: item["identity_name"], value: item["id"], compliancy_type_id: item["compliancy_type_id"] });
                    }
                    )
                    setIdentities(items);
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchAddresses = () => {
        const params = {
            pagination:2
        };
        IdentityService.listAddresses(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data?.data.forEach((item) => {
                        items.push({ value: item["id"], address_line_1: item["address_line_1"], city: item["city"], country: item["country"], label: item["address_line_1"] + ", " + item["city"] + ", " + item["country"] });
                    }
                    )
                    setAddresses(items);
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchComplianceRequirementsProduct = (portinSettingsId) => {
        const params = {
            identity_id: filterIdentity,
            porting_settings_id: portingSettingsIdState,
        };

        IdentityService.fetchComplianceRequirements(params)
            .then((response) => {
                if (response.status === 200) {
                    setComplianceRequirements(response.data.data);
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };


    useEffect(() => {
        fetchIdentities();
        fetchAddresses();
        return () => {
            setData([]);
        }
    }
    , []);


    


    return (
        <>
            <TableFilterContainer>

               {isDocsFetched ? 
               
                <Grid container spacing={2} direction={"column"} justifyContent="left" alignItems="left" xs={12}>
                
                {textFieldDoc?.status == true ? <>


                    <Grid container padding={2} spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Button disabled>
                            <div className={page == 1 ? classes.selectedCircle : classes.circle}>1</div>
                            <Box marginLeft={1}>
                                <Typography color="black">Select Identity</Typography>
                            </Box>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Divider orientation="horizontal" flexItem />
                    </Grid>
                    <Grid item>
                        <Button disabled>
                            <div className={page == 2 ? classes.selectedCircle : classes.circle}>2</div>
                            <Box marginLeft={1}>
                                <Typography color="black">Submit
                                </Typography>
                            </Box>                        </Button>
                    </Grid>
                    <Grid item>
                        <Divider orientation="horizontal" flexItem />
                    </Grid>
                </Grid>

                {page == 1 && (<>
                    <Grid container padding={2} spacing={1} direction={"column"} xs={4}>
            <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Identity"}</InputLabel>
                            <BaseSelect
                                label={"Identity"}
                                labelId="filter-identity-label"
                                name="identity"
                                color="secondary"
                                value={filterIdentity}
                                onChange={event => setFilterIdentity(event.target.value)}
                            >
                                {getSelectOptions(identities, ['0'], false)}
                            </BaseSelect>
                        </FormControl>
                </Grid>
                <br/>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Address"}</InputLabel>
                            <BaseSelect
                                label={"Address"}
                                labelId="filter-address-label"
                                name="address"
                                color="secondary"
                                value={filterAddress}
                                onChange={event => setFilterAddress(event.target.value)}
                            >
                                {getSelectOptions(addresses, ['0'], false)}
                            </BaseSelect>
                        </FormControl>
                    <br/>
                </Grid>
                </Grid>

                <Grid item alignItems="center" justifyContent="flex-start">
                {complianceRequirements != undefined &&  complianceRequirements.descriptions != null && complianceRequirements.descriptions != undefined && (
                         <>
                         <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Descriptions</legend>
                        <>
                            {complianceRequirements.descriptions.map((item, index) => (
                            <>
                                <Grid direction={'column'} alignItems="left">
                                        <Typography sx={{ textAlign: 'left' }}>
                                        {item.description_header}</Typography>
                                            {item.descriptions.map((descr, index) => (
                                                <li key={index} style={{textAlign: 'left'}}>{descr}</li>
                                            ))}
                                    </Grid>
                                    </>
                                    ))}

                            </>  
                    </fieldset>

                    </>
                        )}
                    <br/>

                    {complianceRequirements && complianceRequirements?.template_files?.length > 0 && (
                                                <TemplateFilesForm template_files= {complianceRequirements.template_files} setMessage = {setMessage} setSnackbarStatus = {setSnackbarStatus} />
                                                )}
                </Grid>

                    <Grid item  alignItems="center" justifyContent="flex-start">                     
                    {complianceRequirements != undefined && complianceRequirements.fields.length > 0 ? (
                    <>

                    <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                        <legend>Requirements</legend>
                    {complianceRequirements.fields.map((item, index) => (
                        <>
                        <br/>
                        <Grid container direction={'row'} alignItems="left" justifyContent="flex-start">
                                <Grid item xs={1} container alignItems="center" justifyContent="flex-start">

                                <Typography sx={{ textAlign: 'left' }}>
                                    {item.display_text}</Typography>
                                    </Grid>

                                    <Grid item xs={4} container alignItems="center" justifyContent="flex-start">


                                {item.field_status == 0 && (
                                    <Typography sx={{ textAlign: 'left', color: 'orange' }}>
                                        {'Pending validation'}
                                    </Typography>)
                                }

                                {item.field_status == 1 && (
                                    <Typography sx={{ textAlign: 'left', color: 'green' }}>
                                        {'Already verified'}
                                    </Typography>)
                                }


                                {item.field_status == 2 && item.field_type == 1 && (
                                        <>
                                        <Grid container alignItems="center" justifyContent="left">
                                            <BaseButton>
                                                <input
                                                    accept={'.' + item.allowed_extensions.join(', .')}
                                                    id={item.id}
                                                    type="file"
                                                    onChange={(event) => handleFileChangeCompliance(event, item.id)}
                                                    style={{ height: 35, alignContent: 'center' }}
                                                />
                                            </BaseButton>
                                        </Grid>
                                    </>
                                )
                                }

                                {item.field_status == 2 && item.field_type == 2 && (
                                        <BaseTextField
                                        sx = {{width: '100%'}}
                                            key={item.id}
                                            value={null}
                                            name="number"
                                            margin="normal"
                                            variant="outlined"
                                            color="secondary"
                                            onChange={event => handleTextFieldChangeCompliance(event, item.id)}
                                        />
                                )
                                }

                                </Grid>


                                <Grid item xs={3} container alignItems="center" justifyContent="center">
                                {item.field_status == 2 && item.reject_comment != undefined && item.reject_comment != null && item.reject_comment != "" && (
                                
                                <Typography sx={{ textAlign: 'left', color: 'red' }}>
                                    {item.reject_comment}
                                </Typography>)
                                }  

                                </Grid>

                                <br/>
                                </Grid>
                            
                                </>
                                
                    ))}
                    <br/>
                        <Typography sx={{ textAlign: 'left', color: 'red'}}>
                                    {"* All fields are required"}  
                                </Typography>

                        
                        </fieldset>
                        </>
                    ) : (
                        <></>
                    )}
                    <br/>

                        <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                            <SearchButtonContainer item >
                            <BaseButton sx={{ marginRight: 1 }}
                                    label={'Cancel'}
                                    onClick={null} color={"primary"}
                                />

                            <LoadingButton sx={{ marginRight: 1 }} onClick={() => sendPortOutRequest()} loading={loading} color={"primary"} margin="normal" variant="contained" component="span">
                                {'Submit'}
                            </LoadingButton>

                            </SearchButtonContainer>
                        </Grid>

                    </Grid>
                </>)}

                </>
                
                : <>
                <Grid container justifyContent="left" alignItems="left" spacing={2} direction={"column"} justifyItems={"left"}>
                    <Grid item> 
                <Typography color="black">{"No porting settings found for this criteria."}</Typography>
                    </Grid>
                    <Grid item>
                    <BaseButton label={'Cancel'} 
                            onClick={() => { setModalStatus(false)}} color={"--color-primary"} />
                    </Grid>
                </Grid>
                </>
                }
                
                </Grid> 
                
                : <CircularProgress />}
            </TableFilterContainer>
            <br/>
        </>
    );
}
