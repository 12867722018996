const urls = {
    //TELESMART
    login: "portal/login",
    login_sso: "portal/login_sso",
    register: "portal/register",
    refresh_token: "portal/refresh_token",
    list_numbers: "/portal/numbers", //GET
    number_detail: "/portal/didpool/number_detail", //GET
    schedule_request: "/portal/change-request/translation-request", //POST
    cancel_request: "/portal/change-request/translation-request-cancel", //POST
    countries: "portal/countries", // GET
    register_countries: "portal/register_countries", // GET
    states: 'portal/countries/states', // GET
    cities: 'portal/countries/cities', // GET
    did_types: "portal/did_types", // GET
    did_types_with_filter: "portal/did_types_with_filter", // GET
    capabilities_with_filter: "portal/capabilities_with_filter", // GET
    deallocate_number: "portal/didpool/deallocate", //DELETE
    endpoints: "portal/interconnection/voice/list", //GET
    sms_endpoints: "portal/interconnection/sms/list", //GET
    list_preorders: "portal/purchaseorder/pre-orders", //GET
    list_orders: "portal/purchaseorder/orders", //GET
    purchaseorder_detail: (id) => { return `portal/purchaseorder/order/detail/${id}`; },
    preorder_detail: (id) => { return `portal/purchaseorder/pre-order/detail/${id}`; },
    list_requests_translations: "portal/change-request/translations", //GET
    list_requests_deallocations: "portal/change-request/deallocations", //GET
    export_numbers: "portal/didpool/export", //GET
    get_provider_codes: "portal/provider_codes", // GET
    get_portal_numbers: "portal/search", // GET
    upload_files_buy_number: "portal/fileupload/upload_file", //POST
    buy_number: "/portal/order", //POST
    get_rejected_documents: "/portal/fileupload/rejected_doc_lookup", //GET
    submit_rejected_documents: "/portal/fileupload/doc_re_submit", // POST
    number_request: "portal/number_request", // POST
    update_number_request: "portal/update_number_request", // POST
    list_routing: "/portal/routing/list", //GET
    list_routing_sms: "/portal/routing_sms/list", //GET
    list_interconnection: "/portal/interconnection/voice/list?inbound_enabled=true", //GET
    list_sms_interconnection: "/portal/interconnection/sms/list", //GET
    update_routing_voice: "/portal/routing/route", // POST
    update_routing_sms: "/portal/routing_sms/route", // POST
    notifications: "/portal/inbox/notifications", // POST
    notification: (id) => { return `/portal/inbox/notification/${id}`; }, // PATCH
    listPorting: "portal/porting/list",
    listSettings: "/portal/porting/settings",
    listPortInDidTypes: "/portal/porting_did_types",
    add: "/portal/porting/add",
    update: "/portal/porting/update",
    reschedule_porting: "/portal/porting/update?reschedule=true",
    translate: (id) => {return `portal/porting/translate/${id}`;},
    listPortOutDetail: (id) => {return `portal/porting/detail/${id}`;},
    update_number_translation: "/portal/didpool/translate", // POST
    invite_team_member: "/portal/invite", // POST
    list_team_members: "portal/organisation/members",
    resetPassword_team_member: "portal/reset_password",
    accept_invitation: "portal/accept_invitation",
    set_new_password: "portal/set_new_password",
    
    // CDRs
    list_logs_sms: "portal/logs/sms", // GET
    list_logs_voice: "portal/logs/voice", // GET
    list_outbound_sms: "portal/logs/outbound_sms", // GET
    list_outbound_voice: "portal/logs/outbound_voice", // GET
    list_forwarded_voice: "portal/logs/forwarded_voice", // GET

    list_inbound_rates: "portal/portal_tariffs", //GET
    list_outBound_voice_rates : "portal/outbound_voice_tariffs", //GET
    list_outBound_sms_rates: "portal/outbound_sms_tariffs", //GET
    get_products: "/portal/products", //GET
    get_product: "/portal/product", //GET
    get_style: "/portal/portal_settings", // GET
    dashboard: "/portal/dashboard", // GET
    update_profile: "/portal/update_profile", // POST
    send_sms: "/portal/outboundsms/send", // POST

    download_file: "/portal/fileupload/download_file", // POST
    download_media_file: (guid) => { return `/portal/download/${guid}`}, // POST

    download_outbound_voice_tariffs: "/portal/download_outbound_voice_tariffs", // GET
    download_portal_tariffs: "/portal/download_portal_tariffs", // GET

    list_identities: "/portal/identities", // GET

    get_compliancy_requirement: "/portal/compliancy/get_compliancy_requirements", // GET
    
    list_identities: "/portal/identities", // GET
    list_addresses: "/portal/addresses", // GET
    update_address: (id) => { return `/portal/address/${id}`; }, // PATCH
    delete_address: (id) => { return `/portal/address/${id}`; }, // DELETE
    create_identity: "/portal/identity", // POST
    create_address: "/portal/address", // POST
    upload_file: "portal/fileupload/upload_file", //POST
    list_sub_customers: "/portal/sub_customers", // GET
    create_sub_customer: "/portal/sub_customer", // POST

    update_sub_customer: (id) => { return `/portal/sub_customer/${id}`; }, // PATCH
    delete_sub_customer: (id) => { return `/portal/sub_customer/${id}`; }, // DELETE

    delete_identity: (id) => { return `/portal/identity/${id}`; }, // DELETE
    update_identity: (id) => { return `/portal/identity/${id}`; }, // DELETE

    list_available_channels: "/portal/channel-management/available-product-channels",
    list_your_product_channels: "/portal/channel-management/your-product-channels",
    buy_product_channel: "/portal/channel-management/buy-product-channel",
    remove_your_product_channel: "/portal/channel-management/remove-product-channel",

    create_paypal_order: "portal/transaction/create_paypal_order",
    finalize_paypal_order: "portal/transaction/finalize_paypal_order",
    bank_transfer_request: "portal/request_invoice",
    card_order_request: "portal/transaction/create_order",

    delete_notification: (id) => { return `/portal/inbox/notification/${id}`; }, // PATCH
    unread_notification: (id) => { return `/portal/inbox/notification/${id}`; }, // PATCH
    delete_notification_forever: (id) => { return `/portal/inbox/notification/${id}`; }, // PATCH

    add_voice_endpoint: "portal/interconnection/voice/add", // POST
    update_voice_endpoint: (id) => { return `portal/interconnection/voice/${id}`; }, // PATCH
    update_sms_endpoint: (id) => { return `portal/interconnection/sms/${id}`; }, // PATCH
    update_sms_interconnection: (id) => { return `portal/inbox/notification/{id}/${id}`; }, // PATCH
    add_sms_interconnection:  "portal/interconnection/sms/add", // POST
    get_transactions:  "portal/transaction/list", // POST


    //// CART

    add_to_cart: "portal/cart/add", // POST
    remove_from_cart: "/portal/cart/remove", // POST
    get_cart: "portal/cart/get", // GET
    clear_cart: "portal/cart/clear", // POST


    // SENDER ID
    sender_id_requests: "portal/senderid_request/list", // GET
    sender_id_inventory: "portal/senderid/inventory/list", // GET
    sender_id_request: "portal/senderid_request/add", // POST
    sender_id_country: "portal/senderid_country/list", // GET
    sender_id_cancel: "portal/senderid_request/cancel", // POST
    sender_id_rule: "portal/senderid_rule/check", // POST
    sender_id_resubmit: "portal/senderid_request/resubmit", // POST

    // BLOCKS
    block_search: "portal/search-block", // GET
    your_blocks: "portal/numbers-block", // GET
    block_info: "portal/allocated-block-detail", // GET
    deallocate_block: "portal/didpool/deallocate-block", // POST


    // common
    tts_languages: "tts_languages/", // GET
    number_enabled_countries: "organizations/phone_numbers/countries/", // GET
    documentation: "portal/swagger.json", // GET
    // user
    admin_login: "login/", // TODO: convert back to admin_login when roles are implemented
    get_profile: (id) => { return `users/${id}/`; }, // GET
    update_selected_language: (id) => { return `users/${id}/update_selected_language/`; }, // PUT
    change_password: "change_password/", // PUT
    // 2fa

    tfa_service_reports: "reports/2fa_services/",
    account_reports: "reports/account/",
    //number masking
    number_masking_services: "number_masking_services/",
    masking_numbers: "masking_numbers/",
    add_masking_number: "number_masking_services/add_masking_number/",
    number_masking_sessions: "number_masking_sessions/",
    number_masking_participants: "number_masking_participants/",
    // contacts
    list_add_contacts: "contacts/",
    list_add_contact_groups: "contact_groups/",
    assign_contact: "contact_groups/assign_contact/", // POST
    list_campaigns: "campaigns/", // GET
    add_campaign: "campaigns/", // POST
    list_announcements: "announcements/", // GET
    add_announcement: "announcements/", // POST
    get_file: (id) => {return `files/${id}`},
    list_sms: "programmable_sms/", // GET
    add_credit_paypal: (id) => { return `sub_organizations/${id}/create_paypal_redirect_url/`; }, // POST
    finalize_credit_paypal: "sub_organizations/execute_paypal_payment/", // GET
    //--------------------------------admin-----------------------------------//
    // organization accounts
    list_customers: "organizations/admin/", // GET
    create_customer: "sub_organizations/", // POST
    list_partitions: "organizations/admin/list_partitions/", // GET
    list_sms_tariffs: "/sms_tariffs/admin/", // GET
    add_sms_tariff: "/sms_tariffs/admin/", // POST
    list_voice_tariffs: "/voice_tariffs/admin/", // GET
    add_voice_tariff: "/voice_tariffs/admin/", // POST
    list_tts_tariffs: "/tts_tariffs/admin/", // GET
    add_tts_tariff: "/tts_tariffs/admin/", // POST
    list_stt_tariffs: "/stt_tariffs/admin/", // GET
    add_stt_tariff: "/stt_tariffs/admin/", // POST
    list_number_tariffs: "/number_tariffs/admin/", // GET
    add_number_tariff: "/number_tariffs/admin/", // POST
    list_tfa_tariffs: "/tfa_tariffs/admin/", // GET
    add_tfa_tariff: "/tfa_tariffs/admin/", // POST
    list_number_masking_tariffs: "/number_masking_tariffs/admin/", // GET
    add_number_masking_tariff: "/number_masking_tariffs/admin/", // POST


   // roles
    list_roles: "/organizations/roles/", // GET
    list_role_functions: "/organizations/roles/functions/", // GET
    add_role: "/organizations/roles/", // POST
    // numbers
    add_number: "/organizations/phone_numbers/", //POST
    search_numbers: "/organizations/phone_numbers/search_numbers/", //GET
    get_forwareded_numbers: "organizations/phone_numbers/get_forwareded_numbers", // GET
    // site config
    set_style: "/sites/update_style/", // PUT
    set_logo: "/sites/update_logo/", // POST
    set_favicon: "/sites/update_favicon/", // POST
    get_all_supported_languages: 'supported_languages/', // GET
    update_supported_languages: 'supported_languages/update_supported_languages/', // POST
    // number masking
    list_number_masking_services: "/number_masking_services/", // GET
    list_masking_numbers: "/masking_numbers/", // GET
    list_number_masking_sessions: "/number_masking_sessions/", // GET
    list_number_masking_sessions_participants: "/number_masking_participants/", // GET
    assign_masking_number_to_service: "/number_masking_services/add_masking_number/", // POST


}

export default urls;
