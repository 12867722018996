import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Typography, Box, Button, Divider, Tab
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {
    DefaultPaginationData,
    getSelectOptions
} from 'src/constants/index';
import {SenderIDService, IdentityService, NumberService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import { makeStyles } from "@mui/styles";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {useNavigate} from "react-router-dom";
import BaseCard from "../../../components/BaseCard";
import {useStore} from "../../../store/Store";
import BaseSelect from "../../../components/BaseSelect";
import BaseTextField from "../../../components/BaseTextField";
import { LoadingButton } from '@mui/lab';
import BaseSearchableSelect from 'src/components/BaseSearchableSelect';

const useStyles = makeStyles((theme) => ({
    selectedCircle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
    circle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "darkgray",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
}));

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "primary",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "primary",
    },
}));

export default function AddSenderIDRequest({callback}) {
    const { t } = useTranslation();

    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);

    const [countries, setCountries] = useState([]);

    const [interconnections, setInterconnections] = useState([]);
    const [filterCountry, setFilterCountry] = useState("");
    const [endpoint, setEndpoint] = useState(undefined);
    const [loadingData, setLoadingData] = useState(false);

    const [uploading, setUploading] = useState(false);

    const navigate = useNavigate();


    //--------------------------------------------------------
    const [identities, setIdentities] = useState([]);
    const [addresses, setAddresses] = useState([]);

    const [filterIdentity, setFilterIdentity] = useState(undefined);
    const [filterAddress, setFilterAddress] = useState(undefined);

    const [complianceRequirements, setComplianceRequirements] = useState(undefined);
    const [textFieldValues, setTextFieldValues] = useState({});
    const [fileData, setFileData] = useState([]);

    const [senderRuleExists, setSenderRuleExists] = useState(false);

    const [senderIDRuleId, setSenderIDRuleId] = useState(undefined);

    const [senderIDValue, setSenderIDValue] = useState("");

    const [senderIDNotes, setSenderIDNotes] = useState("");

    useEffect(() => {
        
    }, [fileData]);

    useEffect(() => {
    }, [textFieldValues]);
  

    const fetchIdentities = () => {
        const params = {
            pagination:2,
            identity_type: 1
        };
        IdentityService.listIdentities(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    const identityData = response.data?.data || [];
                    identityData.forEach((item) => {
                        items.push({ 
                            code: item["id"], 
                            name: item["identity_name"], 
                            label: item["identity_name"], 
                            value: item["id"], 
                            compliancy_type_id: item["compliancy_type_id"] 
                        });
                    });
                    setIdentities(items);
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    const fetchAddresses = () => {
        const params = {
            pagination:2
        };
        IdentityService.listAddresses(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    const addressData = response.data?.data || [];
                    addressData.forEach((item) => {
                        items.push({ 
                            value: item["id"], 
                            address_line_1: item["address_line_1"], 
                            city: item["city"], 
                            country: item["country"], 
                            label: `${item["address_line_1"]}, ${item["city"]}, ${item["country"]}` 
                        });
                    });
                    setAddresses(items);
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    

    const fetchComplianceRequirementsProduct = (senderIDRuleId) => {
        setComplianceRequirements(undefined);
        const params = {
            identity_id: filterIdentity,
            sender_id_rule_id: senderIDRuleId,
        };

        IdentityService.fetchComplianceRequirements(params)
            .then((response) => {
                if (response.status === 200) {
                    setComplianceRequirements(response.data.data);
                    if(response.data.data != undefined && response.data.data.restricted == true){
                        setMessage("This product is restricted for this identity");
                        setSnackbarStatus(true);
                        return;
                    }
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };


    useEffect(() => {
        fetchIdentities();
        fetchAddresses();
        fetchSenderCountries();
        return () => {
            setData([]);
        }
    }
    , []);


    const generatePayloadFromState = () => {
        const payload = {
            sender_id_rule_id: senderIDRuleId,
            sender_id: senderIDValue,
            identity_id: filterIdentity,
            address_id: filterAddress,
            country_id: filterCountry,
            customer_notes: senderIDNotes,
            compliances: []
        };

        fileData.forEach((item) => {
            payload.compliances.push({
                'id': item.id,
                'type': 1,
                'data_file': {
                    'name': item.data_file.name,
                    'extension': item.data_file.extension,
                    'mime_type': item.data_file.mime_type,
                    'data': item.data_file.data
                }
            });
        }
        );

        console.log(textFieldsDataCompliance);

        Object.keys(textFieldsDataCompliance).forEach((key) => {
            payload.compliances.push({
                'id': parseInt(key),
                'type': 2,
                'data_text': {
                    'value': textFieldsDataCompliance[key]
                }
            });
            }
        );

        return payload;
    };


    const sendSenderIdRequest = () => {

        if(filterIdentity == undefined || filterIdentity == null || filterAddress == undefined || filterAddress == null || filterIdentity == 0 || filterAddress == 0){
            setMessage("Please select an identity and address");
            setSnackbarStatus(true);
            return;
        }

        const countryCodeFromIso = countries.find(country => country.value === filterCountry);

        let countryCode = countryCodeFromIso.iso_code_2digit;

        const payload = generatePayloadFromState();

        let missingFields = [];

        if(complianceRequirements != undefined && complianceRequirements.fields.length > 0){
            complianceRequirements.fields.forEach((item) => {
                if(item.field_status == 2 && item.field_type == 1){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 1){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }else if(item.field_status == 2 && item.field_type == 2){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 2){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }
            });
        }

        const textFieldsArray = [];

        for (const key in textFieldsDataCompliance) {
            const value = textFieldsDataCompliance[key];
            textFieldsArray.push({
                key: key,
                value: value,
            });
        }

        setUploading(true);

        SenderIDService.addRequest(payload)
            .then((response) => {
                if (response.data.status === true) {
                    setUploading(false);
                    setMessage("Sender-ID request has been succesfully sent");
                    setSnackbarStatus(true);
                    callback(0);
                } else {
                    throw {message:"Request could not be sent", response:response};
                }
            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
                setUploading(false);
            })
    }


    useEffect(() => {
        fetchSenderIdRule();
    }, [filterCountry, filterIdentity]);


        const [textFieldsDataCompliance, setTextFieldsDataCompliance] = useState({});


        const handleTextFieldChangeCompliance = (event, id) => {
            setTextFieldsDataCompliance((prevValues) => ({
                ...prevValues,
                [id]: event,
            }));
        };

      
        const handleFileChangeCompliance = (event, id) => {
            const file = event.target.files[0];
    
            const selectedFile = event.target.files[0];
            const reader = new FileReader();
      
              reader.readAsDataURL(selectedFile);
      
              reader.onload = () => {
                  const base64 = reader.result;
                  const file = {
                      id: id,
                      data_file: {
                          data: base64,
                          name: selectedFile.name,
                          extension: selectedFile.name.split('.').pop(),
                          mime_type: selectedFile.type
                      }
                  };
                  setFileData((prevFiles) => [...prevFiles.filter(file => file.id !== id), file]);
              }
    
        };



    const [uploadProgressMap, setUploadProgressMap] = useState({});


    const [uploadedFileInfo, setUploadedFileInfo] = useState([]);


    const [selectedFile, setSelectedFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false);



    const handleFileChange = (label) => (event) => {
        const selectedFile = event.target.files[0];
        const fileWithLabel = { file: selectedFile, label: label };

        setSelectedFile((prevSelectedFiles) => [...prevSelectedFiles, fileWithLabel]);
    };

    const updateUploadProgress = (label, percentage) => {
        setUploadProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [label]: percentage,
        }));
    };

    const uploadFiles = async () => {
        setIsUploading(true);
        setMessage("Uploading Files");
        setSnackbarStatus(true);
        try {
            const promises = selectedFile.map((file) => {
                const form = new FormData();
                form.append('files', file.file);
                const onUploadProgress =   {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let precentage = Math.floor((loaded * 100) / total);
                        updateUploadProgress(file.label, precentage);
                    }
                }

                return NumberService.uploadFilesBuyNumber(form, onUploadProgress)
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.meta.code === 200) {
                                setMessage("File uploaded");
                                setSnackbarStatus(true);

                                const uploadedFiles = response.data.data;
                                const guidsByLabel = [];

                                uploadedFiles.forEach((uploaded) => {
                                    guidsByLabel.push({
                                        guid: uploaded.guid,
                                        document: file.label,
                                    });
                                });
                                return guidsByLabel;
                            }
                        } else {
                            throw new Error("Error uploading file");
                        }
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                        setSnackbarStatus(true);
                    });
            });

            const results = await Promise.all(promises);
            const mergedGuidsByLabel = results.reduce((acc, curr) => acc.concat(curr), []);

            setUploadedFileInfo(mergedGuidsByLabel);
            console.log(mergedGuidsByLabel);
            setMessage("All Files Uploaded");
            setSnackbarStatus(true);
        } catch (error) {
            console.error(error);
            setMessage(error.message.toString());
            setSnackbarStatus(true);
        } finally {
            setIsUploading(false);
        }
    };


    const [textFields, setTextFields] = useState([""]);


    const fetchInterconnection = () => {
        setLoadingData(true);
        NumberService.listInterconnection()
            .then((response) => {
                setInterconnections(response.data.data.items);
                setEndpoint(response.data.data.items[0]['id']);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const [activePage, setActivePage] = useState(0);


    const removeFile = (fileName) => {
        setSelectedFile(prevFiles => prevFiles.filter(file => file.file.name !== fileName));
    };




    const [store, dispatch] = useStore();
    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    

    const fetchSenderIdRule = () => {
        if(filterCountry == undefined || filterCountry == null || filterCountry == 0){
            return;
        }
        setSenderRuleExists(false);
        
        let iso_code_2digit = countries.find(country => country.value === filterCountry).iso_code_2digit;

        SenderIDService.getRule({iso_code_2digit: iso_code_2digit})
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage("Sender-ID Rule exists");
                    setSnackbarStatus(true);
                    setSenderRuleExists(true);
                    setSenderIDRuleId(response.data.data.id);
                    fetchComplianceRequirementsProduct(response.data.data.id);
                } else {
                    throw new Error("Sender-ID Rule does not exist");
                }
            })
            .catch((err) => {
                setMessage(err.toString());
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }


    const fetchSenderCountries = () => {
        SenderIDService.getCountries()
            .then((response) => {
                let items = [];
                for (const idx in response.data.data.items) {
                    items.push({
                        value: response.data.data.items[idx].id,
                        label: response.data.data.items[idx].country,
                        iso_code_2digit: response.data.data.items[idx].iso_code_2digit
                    });
                }
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    return (
        <>
        <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
        <BaseCard title={t('Port-In Request')}>
            {activePage === 0 && (<TableFilterContainer>
            <Grid container padding={2} spacing={1} direction={"column"} xs={3}>
            <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                        <BaseSearchableSelect label={"Identity"} options={identities} state={filterIdentity} setState={setFilterIdentity} key={'filterIdentity'} />
                        </FormControl>
                        <Typography sx={{ textAlign: 'left', marginTop: 1 }}>
                                    {identities.find(item => item.value == filterIdentity) ? identities.find(item => item.value == filterIdentity).compliancy_type_id == 1 ? "Company Identity Selected" : "Personal Identity Selected" : ""}
                        </Typography>
                </Grid>
                <br/>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <BaseSearchableSelect label={"Address"} options={addresses} state={filterAddress} setState={setFilterAddress} key={'filterAddress'} />
                    <br/>
                </Grid>
                </Grid>
                <br/>
                <>
                <Grid container padding={2} spacing={2}>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('Country')}</InputLabel>
                            <BaseSelect
                                label={t('Country')}
                                labelId="filter-status-label"
                                name="country"
                                color="secondary"
                                value={filterCountry}
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                                <TextField
                                    value={senderIDValue}
                                    label={'Sender ID'}
                                    name={'Sender ID'}
                                    multiline={true}
                                    rowsMax={3}
                                    variant="outlined"
                                    sx={{marginBottom: 1, backgroundColor: 'white', borderRadius: '10px' }}
                                    onChange={(event) => setSenderIDValue(event.target.value)}
                                />
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={senderIDNotes}
                                label={t('Notes')}
                                name="notes"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setSenderIDNotes}
                            />
                        </FormControl>
                    </Grid>
                    </Grid>


                    {complianceRequirements != undefined &&  complianceRequirements.descriptions != null && complianceRequirements.descriptions != undefined && (
                         <>
                         <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Descriptions</legend>
                        <>
                            {complianceRequirements.descriptions.map((item, index) => (
                            <>
                                <Grid direction={'column'} alignItems="left">
                                        <Typography sx={{ textAlign: 'left' }}>
                                        {item.description_header}</Typography>
                                            {item.descriptions.map((descr, index) => (
                                                <li key={index} style={{textAlign: 'left'}}>{descr}</li>
                                            ))}
                                    </Grid>
                                    </>
                                    ))}

                            </>  
                    </fieldset>

                    </>
                        )}


                    <br/>

                    {complianceRequirements != undefined && complianceRequirements.fields.length > 0 ? (
                    <>

                    <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                        <legend>Requirements</legend>
                    {complianceRequirements.fields.map((item, index) => (
                        <>
                        <br/>
                        <Grid container direction={'row'} alignItems="left" justifyContent="flex-start">
                                <Grid item xs={1} container alignItems="center" justifyContent="flex-start">

                                <Typography sx={{ textAlign: 'left' }}>
                                    {item.display_text}</Typography>
                                    </Grid>

                                    <Grid item xs={4} container alignItems="center" justifyContent="flex-start">


                                {item.field_status == 0 && (
                                    <Typography sx={{ textAlign: 'left', color: 'orange' }}>
                                        {'Pending validation'}
                                    </Typography>)
                                }

                                {item.field_status == 1 && (
                                    <Typography sx={{ textAlign: 'left', color: 'green' }}>
                                        {'Already verified'}
                                    </Typography>)
                                }


                                {item.field_status == 2 && item.field_type == 1 && (
                                        <>
                                        <Grid container alignItems="center" justifyContent="left">
                                            <BaseButton>
                                                <input
                                                    accept={'.' + item.allowed_extensions.join(', .')}
                                                    id={item.id}
                                                    type="file"
                                                    onChange={(event) => handleFileChangeCompliance(event, item.id)}
                                                    style={{ height: 35, alignContent: 'center' }}
                                                />
                                            </BaseButton>
                                        </Grid>
                                    </>
                                )
                                }

                                {item.field_status == 2 && item.field_type == 2 && (
                                        <BaseTextField
                                        sx = {{width: '100%'}}
                                            key={item.id}
                                            value={null}
                                            name="number"
                                            margin="normal"
                                            variant="outlined"
                                            color="secondary"
                                            onChange={event => handleTextFieldChangeCompliance(event, item.id)}
                                        />
                                )
                                }

                                </Grid>


                                <Grid item xs={3} container alignItems="center" justifyContent="center">
                                {item.field_status == 2 && item.reject_comment != undefined && item.reject_comment != null && item.reject_comment != "" && (
                                
                                <Typography sx={{ textAlign: 'left', color: 'red' }}>
                                    {item.reject_comment}
                                </Typography>)
                                }  

                                </Grid>

                                <br/>
                                </Grid>
                            
                                </>
                                
                    ))}
                    <br/>
                        <Typography sx={{ textAlign: 'left', color: 'red'}}>
                                    {"* All fields are required"}  
                                </Typography>

                        
                        </fieldset>
                        </>
                    ) : (
                        <></>
                    )}

                    </>

                    <br/>


                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                            <SearchButtonContainer item >
                            <BaseButton sx={{ marginRight: 1 }}
                                    label={'Cancel'}
                                    onClick={() => callback(0)} color={"primary"}
                                />

                             <BaseButton
                                    label={'Submit'} sx={{ marginRight: 1 }}
                                    onClick={() => sendSenderIdRequest(false)} color="--color-primary" props={{ disabled: senderRuleExists == false }}
                                />
                            </SearchButtonContainer>
                        </Grid>

            </TableFilterContainer>)}
        </BaseCard>
        </>
    );
}
