import React from 'react';
import { useState, useEffect } from 'react';
import {Box, FormControl, Grid, IconButton, Stack, useTheme, InputLabel} from '@mui/material';

import { DefaultPaginationData,
    getColorByValue,
    getLabelByValue,
    getSelectOptions,
    IdentityType,
    IdentityTypeOptions,
    IdentityVerificationStatus,
    MembersStatus} from 'src/constants/index';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer, BaseTable } from 'src/components/table';
import BaseButton from 'src/components/buttons/BaseButton';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseModal from 'src/components/BaseModal';
import { IdentityService } from 'src/api/services';
import BaseCard from 'src/components/BaseCard';
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import {Download} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import PortoutDialog from "../number/Forms/PortoutDialog";
import DeleteIdentityDialog from "./Forms/DeleteIdentityDialog";
import AddPersonalIdentityForm from './Forms/AddPersonalIdentityForm';
import EditPersonalIdentityForm from './Forms/EditPersonalIdentity';
import { da } from 'date-fns/locale';
import dayjs from 'dayjs';
import {CommonService} from 'src/api/services';
import EditOrganizationIdentityForm from './Forms/EditOrganizationIdentity';
import BaseDialog from 'src/components/BaseDialog';
import BaseTextField from 'src/components/BaseTextField';
import BaseSelect from 'src/components/BaseSelect';
import SearchButton from 'src/components/buttons/SearchButton';
import { styled } from '@mui/system';


// ----------------------------------------------------------------------

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function PersonalIdentities() {
    const {t} = useTranslation();
    const [openAddMemberModal, setAddMemberModalStatus] = useState(false);
    const [openDeleteDialog, setDeleteDialogStatus] = useState(false);
    const [openEditDialog, setEditDialogStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [countries, setCountries] = useState([]);
    const [openEditOrgIdentity, setOpenEditOrgIdentity] = useState(false);
    const [filterName, setFilterName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterCompName, setFilterCompName] = useState(null);

    const TABLE_HEAD = [
        { key: "identity_name", label: t("Identity Name") },
        { key: "external_id", label: t("External Id") },
        { key: "contact_email", label: t("Contact Email") },
        { key: "compliancy_type", label: t("Compliancy Type") },
        { key: "verification_status", label: t("Verification Status")},
        { key: "enterprise", label: t("Company Name") },
        { key: "action", label: t("Action")}
        
    ];

    const TABLE_FIELD_MAPPING = {
        "identity_name": { index: 0, label: t("Identity Name") },
        "external_id": { index: 1, label: t("External Id") },
        "contact_email": { index: 2, label: t("Contact Email") },
        "compliancy_type": { index: 3, label: t("Compliancy Type") },
        "verification_status": { index: 4, label: t("Verification Status") },
        "enterprise": { index: 5, label: t("Enterprise") },
        "id": { index: 6, label: "id", noRender: true },
        "properties": { index: 7, label: "properties", noRender: true },
    };


    const fetchIdentities = () => {
        const params = {
            size: paginationData.rowsPerPage,
            page: paginationData.page + 1,
            name: filterName ? filterName : undefined,
            email: filterEmail ? filterEmail : undefined,
            identity_type: filterType ? filterType : undefined,
            status : filterStatus ? filterStatus : undefined,
            company : filterCompName ? filterCompName : undefined 
        };
        setLoadingData(true);
        IdentityService.listIdentities(params)
            .then((response) => {
                if (!response.data || !response.data.data) {
                    throw(t("session-expired"));
                }
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    const itemData = response.data.data.items[idx];
                    
                    let company_name = itemData.properties?.company_name

                    item[TABLE_FIELD_MAPPING["enterprise"].index] = {
                        ...TABLE_FIELD_MAPPING["enterprise"],
                        value: company_name,
                      };

                    Object.entries(itemData).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING && key != "enterprise") {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })

    };

    const downloadFile = (index) => {
        setLoading(true);
        IdentityService.downloadFile({guid: data[index][11]['value']})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data[index][12]['value']);
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["id"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchIdentities();
        fetchCountries();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            console.log(d);
            if (d.label === "Verification Status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(IdentityVerificationStatus(), d.value.toString()),
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    };


    const modalHandler = (modalType, index) => {
        if (modalType === "addMember") {
            setAddMemberModalStatus(!openAddMemberModal);
        }
        if (modalType === "deleteDialog") {
            setDeleteDialogStatus(!openDeleteDialog);
            setSelectedRow(data[index]);
        }
        if (modalType === "editDialog") {
            setEditDialogStatus(!openEditDialog);
            setSelectedRow(data[index]);
        }
    };

    const theme = useTheme();


    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent='start' spacing={1}>
                        <>
                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <EditIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("editDialog", index)}/>
                            </IconButton>
                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <DeleteIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("deleteDialog", index)}/>
                            </IconButton>
                        </>
                </Stack>
            );
      };
      const DIALOG_PROPERTIES = {
        fullWidth: true,
        maxWidth: "xs",
        scroll: "body",
        // fullScreen: true,
    }

    
    return (
        <>
            <Box marginBottom={8}>
                <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
                <BaseCard>

                <TableFilterContainer >

                    <Grid container spacing={2} sx={{padding: 2, gap: 1, alignItems: 'center'}}>
                        <Grid items md={1.9} xs={12}>
                            <FormControl fullWidth>
                                <BaseTextField 
                                    value={filterName}
                                    label={t("Identity Name")}
                                    name="identity-name"
                                    onChange={setFilterName}
                                />
                            </FormControl>
                        </Grid>

                        <Grid items md={1.9} xs={12}>
                            <FormControl fullWidth>
                                <BaseTextField 
                                    value={filterEmail}
                                    label={t("email")}
                                    name="email"
                                    onChange={setFilterEmail}
                                />
                            </FormControl>
                        </Grid>
                        
                        <Grid item md={1.9} xs={12} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-type-label">{t("Compliancy Type")}</InputLabel>
                                <BaseSelect
                                    label={t("Compliancy Type")}
                                    labelId="filter-type-label"
                                    name="compliancy-type"
                                    color="secondary"
                                    value={filterType}
                                    onChange={(event) => {setFilterType(event.target.value)}}
                                >
                                    {getSelectOptions(IdentityTypeOptions())}
                                </BaseSelect>
                            </FormControl>
                        </Grid>

                        <Grid item md={1.9} xs={12} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-status-label">{t("Verification Status")}</InputLabel>
                                <BaseSelect
                                    label={t("Verification Status")}
                                    labelId="filter-status-label"
                                    name="verification-status"
                                    color="secondary"
                                    value={filterStatus}
                                    onChange={(event) => {setFilterStatus(event.target.value)}}
                                >
                                    {getSelectOptions(IdentityVerificationStatus())}
                                </BaseSelect>
                            </FormControl>
                        </Grid>
                        <Grid items md={1.9} xs={12}>
                            <FormControl fullWidth>
                                <BaseTextField 
                                    value={filterCompName}
                                    label={t("Company Name")}
                                    name="company"
                                    onChange={setFilterCompName}
                                />
                            </FormControl>
                        </Grid>

                            <SearchButtonContainer item md={2} xs={12}>
                                <SearchButton onClick={() => { fetchIdentities() }} />
                            </SearchButtonContainer>

                    </Grid>
               
                    <Box alignItems={"center"} sx={{
                        height: 50,
                        display: 'flex'
                        }}>
                        <BaseButton
                            label= {t("+ Add New Identity")}
                            onClick={() => modalHandler("addMember")}
                        />
                    </Box>
                </TableFilterContainer>
                </BaseCard>

                <BaseDialog title={t("Add New Identity")} open={openAddMemberModal} setOpen={setAddMemberModalStatus} children={<AddPersonalIdentityForm successCallback={fetchIdentities} setModalStatus={setAddMemberModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} countries={countries}/>} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchIdentities} />

                <BaseDialog  title={t("Edit Personal Identity")} open={openEditDialog} setOpen={setEditDialogStatus} children={<EditPersonalIdentityForm formData={selectedRow} successCallback={fetchIdentities} setModalStatus={setEditDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchIdentities} />

                <BaseModal title={t("Delete Identity")} open={openDeleteDialog} setOpen={setDeleteDialogStatus} children={<DeleteIdentityDialog formData={selectedRow} successCallback={fetchIdentities} setModalStatus={setDeleteDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} />
            </Box>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />

        </>

    );
}
