// ----------------------------------------------------------------------
import React, {useEffect, useState} from 'react';
import {styled} from '@mui/system';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import {TableFilterContainer} from 'src/components/table';
import {IdentityService, SenderIDService} from 'src/api/services';
import {useTranslation} from 'react-i18next';
import LinearProgress, {linearProgressClasses, LinearProgressProps} from '@mui/material/LinearProgress';
import { getSelectOptions } from 'src/constants';
import BaseSelect from 'src/components/BaseSelect';
import { filter } from 'lodash';
import BaseButton from 'src/components/buttons/BaseButton';
import BaseTextField from 'src/components/BaseTextField';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "primary",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "primary",
    },
}));


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.secondary.dark,
}));


export default function SenderIDResubmitForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, closeModal }) {
    const { t } = useTranslation();


    const [name, setName] = useState("");
    const [endpoint, setEndpoint] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [body, setBody] = useState("");
    const [id, setId] = useState("");
    const [isDefault, setIsDefault] = useState("");



    const [filterIdentity, setFilterIdentity] = useState(formData.identity_id);
    const [identities, setIdentities] = useState([]);

    const [complianceRequirements, setComplianceRequirements] = useState();

    const [textFieldValues, setTextFieldValues] = useState({});

    const [addresses, setAddresses] = useState([]);
    const [filterAddress, setFilterAddress] = useState(formData.address_id);


    const handleTextFieldChange = (event, id) => {
        setTextFieldValues((prevValues) => ({
            ...prevValues,
            [id]: event,
        }));
    };


    useEffect(() => {
        setName(formData.name);
        setEndpoint(formData.http_post_url);
        setFrom(formData.from_field);
        setTo(formData.to_field);
        setBody(formData.body_field);
        setId(formData.id_field);
        setIsDefault(formData.is_default);
        fetchIdentities();
        fetchAddresses();
        fetchComplianceRequirementsProduct();
        console.log(formData);
    }, []);


    const [fileData, setFileData] = useState([]);

    useEffect(() => {
    }, [fileData]);

    useEffect(() => {
    }, [textFieldValues]);
  
    const handleFileChange = (event, id) => {
        const file = event.target.files[0];

        const selectedFile = event.target.files[0];

        let allowedExtensions = complianceRequirements.fields.filter((item) => item.id == id)[0].allowed_extensions;


        allowedExtensions = allowedExtensions.filter((item) => item != "");


        if(allowedExtensions.length > 0){
            if(allowedExtensions.indexOf(selectedFile.name.split('.').pop()) == -1){
                setMessage("Only following file extensions allowed: " + allowedExtensions.join(", "));
                setSnackbarStatus(true);
                event.target.value = null;
                return;
            }
        }

        
        const reader = new FileReader();
  
          reader.readAsDataURL(selectedFile);
  
          reader.onload = () => {
              const base64 = reader.result;
              const file = {
                  id: id,
                  data_file: {
                      data: base64,
                      name: selectedFile.name,
                      extension: selectedFile.name.split('.').pop(),
                      mime_type: selectedFile.type
                  }
              };
              setFileData((prevFiles) => [...prevFiles.filter(file => file.id !== id), file]);
          }

    };


    const fetchIdentities = () => {
        const params = {
            pagination:2
        };
        IdentityService.listIdentities(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data.data.forEach((item) => {
                        items.push({ code: item["id"], name: item["identity_name"], label: item["identity_name"], value: item["id"], compliancy_type_id: item["compliancy_type_id"] });
                    }
                    )
                    setIdentities(items);
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchAddresses = () => {
        const params = {
            pagination:2
        };
        IdentityService.listAddresses(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data.data.forEach((item) => {
                        items.push({ value: item["id"], address_line_1: item["address_line_1"], city: item["city"], country: item["country"], label: item["address_line_1"] + ", " + item["city"] + ", " + item["country"] });
                    }
                    )
                    setAddresses(items);
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchComplianceRequirementsProduct = () => {
        const params = {
            identity_id: filterIdentity,
            sender_id_rule_id: formData.sender_id_rule_id,
            sender_id_id: formData.id
        };

        IdentityService.fetchComplianceRequirements(params)
            .then((response) => {
                if (response.status === 200) {
                    setComplianceRequirements(response.data.data);
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const generatePayloadFromState = () => {
        let payload = {
            id: formData.id,
            identity_id: filterIdentity,
            address_id: filterAddress,
            compliances: []
        };

        fileData.forEach((item) => {
            payload.compliances.push({
                'id': item.id,
                'type': 1,
                'data_file': {
                    'name': item.data_file.name,
                    'extension': item.data_file.extension,
                    'mime_type': item.data_file.mime_type,
                    'data': item.data_file.data
                }
            });
        }
        );


        Object.keys(textFieldValues).forEach((key) => {
            payload.compliances.push({
                'id': parseInt(key),
                'type': 2,
                'data_text': {
                    'value': textFieldValues[key]
                }
            });
        }
        );

        return payload;
    };

    const resubmitPorting = () => {
        if(filterIdentity == undefined || filterIdentity == null || filterAddress == undefined || filterAddress == null){
            setMessage("Please select an identity and address");
            setSnackbarStatus(true);
            return;
        }
        
        const payload = generatePayloadFromState();

        let missingFields = [];


        if(complianceRequirements != undefined && complianceRequirements.fields.length > 0){
            complianceRequirements.fields.forEach((item) => {
                if(item.field_status == 2 && item.field_type == 1){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 1){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }else if(item.field_status == 2 && item.field_type == 2){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 2){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }
            });
        }

        if(missingFields.length > 0){
            setMessage("Please fill in the following fields: " + missingFields.join(", "));
            setSnackbarStatus(true);
            return;
        }

        
        SenderIDService.resubmit(payload)
            .then((response) => {
                if (response.data.meta.code == 200) {
                    setMessage("Sender-ID request has been resubmitted successfully");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw { message: response.data.meta.msg };
                }
            })
            .catch((err) => {
                if(err.message == undefined) {
                    setMessage("Request could not be sent");
                }else{
                    setMessage(err.message);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
                successCallback();
            })
    }


    useEffect(() => {
        fetchComplianceRequirementsProduct();
    }, [filterIdentity]);
    
    return (
        <>
            <TableFilterContainer>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Identity"}</InputLabel>
                            <BaseSelect
                                label={"Identity"}
                                labelId="filter-identity-label"
                                name="identity"
                                color="secondary"
                                disabled={true}
                                value={formData.identity_id}
                                onChange={event => setFilterIdentity(event.target.value)}
                                sx={{ disabled: true}}
                            >
                                {getSelectOptions(identities)}
                            </BaseSelect>
                        </FormControl>
                    <br/>
                </Grid>
                <br/>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Address"}</InputLabel>
                            <BaseSelect
                                label={"Address"}
                                disabled={true}
                                labelId="filter-address-label"
                                name="address"
                                color="secondary"
                                value={formData.address_id}
                                onChange={event => setFilterAddress(event.target.value)}
                            >
                                {getSelectOptions(addresses)}
                            </BaseSelect>
                        </FormControl>
                    <br/>
                </Grid>

                <br/>
                <br/>
                {complianceRequirements != undefined &&  complianceRequirements.descriptions != null && complianceRequirements.descriptions != undefined && (
                         <>
                         <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Descriptions</legend>
                        <>
                            {complianceRequirements.descriptions.map((item, index) => (
                            <>
                                <Grid direction={'column'} alignItems="left">
                                        <Typography sx={{ textAlign: 'left' }}>
                                        {item.description_header}</Typography>
                                            {item.descriptions.map((descr, index) => (
                                                <li key={index} style={{textAlign: 'left'}}>{descr}</li>
                                            ))}
                                    </Grid>
                                    </>
                                    ))}

                            </>  
                    </fieldset>

                    </>
                        )}

                        <br/>

                        {complianceRequirements != undefined && complianceRequirements.fields.length > 0 ? (
                         <>
                       
                         <fieldset style={{ border: '1px solid #ccc', padding: 10, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Requirements</legend>
                         {complianceRequirements.fields.map((item, index) => (
                            <>
                            <br/>
                            <Grid container direction={'row'} alignItems="left" justifyContent="flex-start">
                                    <Grid item xs={1} container alignItems="center" justifyContent="flex-start">

                                    <Typography sx={{ textAlign: 'left' }}>
                                        {item.display_text}</Typography>
                                        </Grid>

                                        <Grid item xs={4} container alignItems="center" justifyContent="flex-start">


                                    {item.field_status == 0 && (
                                        <Typography sx={{ textAlign: 'left', color: 'orange' }}>
                                            {'Pending validation'}
                                        </Typography>)
                                    }

                                    {item.field_status == 1 && (
                                        <Typography sx={{ textAlign: 'left', color: 'green' }}>
                                            {'Already verified'}
                                        </Typography>)
                                    }


                                    {item.field_status == 2 && item.field_type == 1 && (
                                            <>
                                            <Grid container alignItems="center" justifyContent="left">
                                                <BaseButton>
                                                    <input
                                                        accept={'.' + item.allowed_extensions.join(', .')}
                                                        id={item.id}
                                                        type="file"
                                                        onChange={(event) => handleFileChange(event, item.id)}
                                                        style={{ height: 35, alignContent: 'center' }}
                                                    />
                                                </BaseButton>
                                            </Grid>
                                        </>
                                    )
                                    }

                                    {item.field_status == 2 && item.field_type == 2 && (
                                             <BaseTextField
                                             sx={{ width: '100%' }}
                                                 key={item.id}
                                                 value={null}
                                                 name="number"
                                                 margin="normal"
                                                 variant="outlined"
                                                 color="secondary"
                                                 onChange={event => handleTextFieldChange(event, item.id)}
                                             />
                                    )
                                    }

                                    </Grid>


                                    <Grid item xs={3} container alignItems="center" justifyContent="center">
                                    {item.field_status == 2 && item.reject_comment != undefined && item.reject_comment != null && item.reject_comment != "" && (
                                    
                                    <Typography sx={{ textAlign: 'left', color: 'red' }}>
                                        {item.reject_comment}
                                    </Typography>)
                                    }  

                                    </Grid>

                                    <br/>
                                    </Grid>
                                 
                                    </>
                                    
                          ))}
                          <br/>
                             <Typography sx={{ textAlign: 'left', color: 'red'}}>
                                        {"* All fields are required"}  
                                    </Typography>
                   
                            
                            </fieldset>
                            </>
                        ) : (
                            <></>
                        )}

                <br/>

                <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                            <SearchButtonContainer item >
                            <BaseButton sx={{ marginRight: 1 }}
                                    label={'Cancel'}
                                    onClick={() => { closeModal()}} color={"primary"}
                                />

                             <BaseButton
                                    label={'Submit'} sx={{ marginRight: 1 }}
                                    onClick={() => { resubmitPorting()}} color={"--color-search"}
                                />
                            </SearchButtonContainer>
                        </Grid>

            </TableFilterContainer>
        </>
    );
}

